<template>
  <v-app>
    <v-card>
      <v-card-title class="headline"><v-icon @click="back()" size="30" style="color: black">mdi-arrow-left</v-icon> &nbsp;{{ title }} Online Examination </v-card-title>
      <v-container>
        <v-row >
          <v-col cols="12">
            <v-card>
              <v-card-title class="headline">Exam Information</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="Exam Title*" outlined dense v-model="exam.title">
                      </v-text-field>
                      <span class="text-danger" v-if="errors.title" >{{errors.title[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.title.$error">This information is required</span>
                </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field outlined dense
                                label="Subject Code*"
                                v-model="exam.subject_code">
                      </v-text-field>
                      <span class="text-danger" v-if="errors.subject_code" >{{errors.subject_code[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.subject_code.$error">This information is required</span>
                </v-col>
                    <v-col cols="12" sm="6" md="6">

                      <v-autocomplete
                          :items="categories" item-text="name" item-value="id"
                          label="Search by category" clearable v-model="exam.category_id" @change="getSubCategory"
                          deletable-chips dense outlined
                      ></v-autocomplete>
                      <span class="text-danger" v-if="errors.category_id" >{{errors.category_id[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.category_id.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                          :items="sub_categories" item-text="name" item-value="id" @change="getAllGrades"
                          label="Syllabus" clearable v-model="exam.sub_category_id"
                          deletable-chips dense outlined
                      ></v-autocomplete>
                      <span class="text-danger" v-if="errors.sub_category_id" >{{errors.sub_category_id[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.sub_category_id.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                          :items="grades" item-text="title" item-value="id"
                          label="Search by crades" clearable v-model="exam.grade_id" @change="getCourses()"
                          deletable-chips dense outlined
                      ></v-autocomplete>
                      <span class="text-danger" v-if="errors.grade_id" >{{errors.grade_id[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.grade_id.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">

                      <v-autocomplete
                          :items="courses" item-text="title" item-value="id"
                           clearable v-model="exam.course_id"
                          deletable-chips dense outlined
                          label="Add Course Bundle*"
                      ></v-autocomplete>
                      <span class="text-danger" v-if="errors.course_id" >{{errors.course_id[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.course_id.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.expiry_months"
                            :items="expiry_months"
                            item-text="text"
                            outlined
                            dense
                            item-value="value"
                            label="Expiry date"
                  ></v-select>
                      <span class="text-danger" v-if="$v.exam.expiry_months.$error">This information is required</span>
                      <span class="text-danger" v-if="errors.expiry_months" >{{errors.expiry_months[0]}}</span>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.exam_option"
                               :items="exam_options"
                                item-text="text"
                                item-value="value"
                                outlined
                                dense
                                label="Exam Option"
                                required>
                      </v-select>
                      <span class="text-danger" v-if="$v.exam.exam_option.$error">This information is required</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Settings </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">

                      <v-text-field
                          outlined
                          dense
                          placeholder="Duration in Minutes"
                          label="Exam Duration*"
                          v-model="exam.exam_duration"
                      ></v-text-field>
                      <span class="text-danger" v-if="errors.exam_duration" >{{errors.exam_duration[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.exam_duration.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                          dense
                          outlined
                          label="Office Exam ID"
                          v-model="exam.rising_software_key"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                          label="Practice Exam ID"
                          outlined
                          dense
                          v-model="exam.practice_exam_link"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                          outlined
                          dense
                          @keypress="onlyNumber"
                          label="Position *"
                          v-model="exam.position"
                      ></v-text-field>
                      <span class="text-danger" v-if="errors.position" >{{errors.position[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.position.$error">This information is required</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Option </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>

                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.require_playlist"
                                :items="repertoire_submission"
                                item-text="text"
                                item-value="value"
                                outlined
                                dense
                                label="Repertoire Submission"
                                required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.candidate_exam_type"
                                :items="candidate_exam_types"
                                item-text="text"
                                item-value="value"
                                outlined
                                dense
                                label="Performance Type"
                                required
                      ></v-select>
                      <span class="text-danger" v-if="$v.exam.candidate_exam_type.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.ask_for_instrument"
                                :items="ask_for_instruments"
                                item-text="text"
                                outlined
                                dense
                                item-value="value"
                                label="Ask for Instrument"
                                required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                    <v-select v-model="exam.has_hand"
                              :items="has_hand"
                              item-text="text"
                              item-value="value"
                              outlined
                              dense
                              label="Is Hand Specific"
                              required
                    ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.has_instrument_type"
                                :items="has_instrument_types"
                                item-text="text"
                                outlined
                                dense
                                item-value="value"
                                label="Has Instrument Type"
                                required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select v-model="exam.report_available"
                                :items="reports"
                                item-text="text"
                                outlined
                                dense
                                item-value="value"
                                label="Reports"
                                required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <label>Hide second page form on enroller notification</label>
                      <v-select v-model="exam.notification_pdf_second_page"
                                :items="hide_second_page"
                                item-text="text"
                                outlined
                                dense
                                item-value="value"
                                label="Reports"
                                required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Price </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">

                      <v-text-field
                          label="Default Price*"
                          outlined
                          dense
                          placeholder="Global Price"
                          v-model="exam.default_price"
                      ></v-text-field>
                      <span class="text-danger" v-if="$v.exam.default_price.$error">This information is required</span>
                      <span class="text-danger" v-if="errors.default_price" >{{errors.default_price[0]}}</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                          outlined
                          dense
                          label="Exam Appeal Price*"
                          v-model="exam.appeal_price"
                      ></v-text-field>
                      <span class="text-danger" v-if="errors.appeal_price" >{{errors.appeal_price[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.appeal_price.$error">This information is required</span>

                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Types</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" md="12">


                      <v-select
                          v-model="exam.marking_type"
                          :items="marking_types"
                          item-text="text"
                          item-value="value"
                          label="Marketing Types"
                          multiple outlined clearable dense
                          chips
                      ></v-select>
                      <span class="text-danger" v-if="errors.marking_type" >{{errors.marking_type[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.marking_type.$error">This information is required</span>
                    </v-col>
                    <v-col cols="6" md="12">


                      <v-select
                          v-model="exam.certificate_type"
                          :items="certificate_types"
                          text="text"
                          value="value"
                          chips
                          label="Certificate Type"
                          multiple
                          clearable
                          outlined
                          dense
                      ></v-select>
                      <span class="text-danger" v-if="errors.certificate_type" >{{errors.certificate_type[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.certificate_type.$error">This information is required</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Extra</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                          v-model="exam.state_price"
                          :label="`State Price` + ' : ' + statePricesLabel"
                      ></v-switch>

                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                          v-model="exam.on_special"
                          :label="`On Special` + ' : ' + specialLabel"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                          v-model="exam.status"
                          label="Status"
                          :label="`Status` + ' : ' + statusLabel"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                          v-model="exam.visibility"
                          :label="`Visibility` + ' : ' + visibilityLabel"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Fee</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                          label="Transfer Fee"
                          outlined
                          dense
                          v-model="exam.transfer_fee"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                          outlined
                          dense
                          label="Withdrawal Fee"
                          v-model="exam.withdrawal_fee"
                      ></v-text-field>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Group Entry</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" v-bind:md="(group_entry_show==true) ? 6 : 12 ">
                      <v-select v-model="exam.taken_as_group"
                                :items="allow_group_entry"
                                item-text="text"
                                item-value="value"
                                outlined
                                dense
                                label="Allow Group Entry"
                                required
                                @change="groupEntryToggle()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="group_entry_show==true">
                      <v-select label="Group Certificate" v-model="exam.group_certificate"
                                :items="groups_certificates"
                                outlined dense>
                      </v-select>
                      <span class="text-danger" v-if="errors.group_certificate" >{{errors.group_certificate[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.group_certificate.$error">
                              This information is required
                      </span>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="group_entry_show==true">

                      <v-text-field label="Min Candidates*"  @keypress="onlyNumber" outlined dense v-model="exam.num_of_min_candidate">
                      </v-text-field>
                      <span class="text-danger" v-if="errors.num_of_min_candidate" >{{errors.num_of_min_candidate[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.num_of_min_candidate.$error">
                             This information is required
                      </span>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="group_entry_show==true">

                      <v-text-field label="Max Candidates*"  @keypress="onlyNumber" outlined dense v-model="exam.num_of_max_candidate">
                      </v-text-field>
                      <span class="text-danger" v-if="errors.num_of_max_candidate" >{{errors.num_of_max_candidate[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.num_of_max_candidate.$error">
                            This information is required
                      </span>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="group_entry_show==true">

                      <v-text-field label="Price Per Candidate*" outlined dense v-model="exam.price_per_additional_candidate">
                      </v-text-field>
                      <span class="text-danger" v-if="errors.price_per_additional_candidate" >{{errors.price_per_additional_candidate[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.price_per_additional_candidate.$error">
                              This information is required
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Renewable Fee Tye</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12" >
                      <v-select v-model="exam.renewable_fee_type"
                                :items="renewable_fee_types"
                                item-text="text"
                                outlined
                                dense
                                item-value="value"
                                label="Renewal Fee Type"
                                required
                                @change="renewableFeeToggle()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" v-if="renewable_fee_show==true">
                      <v-text-field v-model="exam.renewable_fee" outlined dense
                                    placeholder="Price of the Exam,default 0.00"
                                    label="Renewal Fee*">

                      </v-text-field>
                      <span class="text-danger" v-if="errors.renewable_fee" >{{errors.renewable_fee[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.renewable_fee.$error">
                              This information is required
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title class="headline">MultiMedia</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" v-bind:md="(exam.image != null) ? 8 : 12 "  sm="8">

                      <v-file-input v-model="exam.image" outlined dense
                                    placeholder="Upload Image"  @change="previewImage"
                                    label="File input" prepend-icon="mdi-paperclip">
                      </v-file-input>
                      <span class="text-danger" v-if="errors.image" >{{errors.image[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.image.$error">
                             This information is required
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="exam.image!=null">
                      <div class="text-center">
                        <v-img
                            :lazy-src="imgurl"
                            max-height="150"
                            max-width="250"
                            class="img-thumbnail"
                            :src="imgurl"

                        ></v-img>
                      </div>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">States</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6" md="6">

                      <label>Availability for States*</label>
                      <v-multiselect-listbox
                          :options="states"
                          :reduce-display-property="(option) => option.name"
                          :reduce-value-property="(option) => option.id"
                          v-model="exam.states">
                      </v-multiselect-listbox>
                      <span class="text-danger" v-if="errors.states" >{{errors.states[0]}}</span>
                      <span class="text-danger" v-if="$v.exam.states.$error">
                             This information is required
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title class="headline">Description</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea
                          outlined
                          v-model="exam.description"
                          label="Description"
                          value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                      ></v-textarea>
                      <span class="text-danger" v-if="errors.description" >**{{errors.description[0]}}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="btn btn-primary"
            dark
            medium
            @click="createOnlineExamination"
        >
          Save
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="back()"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>
<script>
import vMultiselectListbox from 'vue-multiselect-listbox';
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
import GradeServce from "@/services/product/grade/GradeService";
import CategoryService from "@/services/product/category/CategoryService";
import SubCategoryService from "@/services/product/subCategory/SubCategoryService";
import StateService from "@/services/setting/state/StateService";
import OnlineExaminationService from "@/services/product/examination/OnlineExaminationService";
import CourseService from "@/services/product/course/CourseService";
import {required, email,requiredIf} from "vuelidate/lib/validators";

const grade = new GradeServce();
const category = new CategoryService();
const subCategory = new SubCategoryService();
const state = new StateService();
const examination=new OnlineExaminationService();
const course = new CourseService();

export default {
  name:"CreateorUpdate",
  validations:{
    exam:{
      title:{required},
      subject_code: {required},
      category_id:{required},
      sub_category_id:{required},
      grade_id:{required},
      course_id:{required},
      expiry_months: {required},
      exam_duration: {required},
      marking_type: {required},
      certificate_type: {required},
      position: {required},
      states: {required},
      default_price: {required},
      appeal_price: {required},
      exam_option: {required},
      candidate_exam_type: {required},
      group_certificate: {
        required: requiredIf(function (nestedModel) {
          return this.group_entry_show==true;
        })
      },
      num_of_min_candidate: {
        required: requiredIf(function (nestedModel) {
          return this.group_entry_show==true;
        })
      },
      num_of_max_candidate: {
        required: requiredIf(function (nestedModel) {
          return this.group_entry_show==true;
        })
      },
      price_per_additional_candidate: {
        required: requiredIf(function (nestedModel) {
          return this.group_entry_show==true;
        })
      },
      image: {
        required: requiredIf(function (nestedModel) {
          return this.examinationId==null || this.examinationId==undefined;
        })
      },
      renewable_fee: {
        required: requiredIf(function (nestedModel) {
          return this.renewable_fee_show==true;
        })
      },
    },
  },
  components: {
    vMultiselectListbox
  },
  data(){
    return{
      renewable_fee_show:false,
      group_entry_show:false,
      imgurl:null,
      title:'Create',
      expiry_months: [
        { text: '1 Month',value:1},
        { text: '2 Month',value:2},
        { text: '3 Month',value:3},
        { text: '4 Month',value:4},
        { text: '5 Month',value:5},
        { text: '6 Month',value:6},
        { text: '7 Month',value:7},
        { text: '8 Month',value:8},
        { text: '9 Month',value:9},
        { text: '10 Month',value:10},
        { text: '11 Month',value:11},
        { text: '12 Month',value:12},
        { text: '13 Month',value:13},
        { text: '14 Month',value:14},
        { text: '15 Month',value:15},
        { text: '16 Month',value:16},
        { text: '17 Month',value:17},
        { text: '18 Month',value:18},
        { text: '19 Month',value:19},
        { text: '20 Month',value:20},
        { text: '21 Month',value:21},
        { text: '22 Month',value:22},
        { text: '23 Month',value:23},
        { text: '24 Month',value:24},
        { text: '25 Month',value:25},
        { text: '26 Month',value:26},
        { text: '27 Month',value:27},
        { text: '28 Month',value:28},
        { text: '29 Month',value:29},
        { text: '30 Month',value:30},
        { text: '31 Month',value:31},
        { text: '32 Month',value:32},
        { text: '33 Month',value:33},
        { text: '34 Month',value:34},
        { text: '35 Month',value:35},
        { text: '36 Month',value:36},
      ],
      grades:[],
      states: [],
      repertoire_submission: [
        { text: 'Yes',value:1},
        { text: 'No',value:0}
      ],
      courses: [],
      renewable_fee_types: [
        { text: 'Default',value:'default'},
        { text: 'Custom',value:'custom'},
        { text: 'Non-renewable',value:'non-renewable'},
      ],
      marking_types:[
        {text:'Automated Marketing',value:'automated'},
        {text:'Manual Marketing ',value:'manual'}
      ],
      certificate_types:[
        {text:'E-Certificate Marketing',value:'e-certificate'},
        {text:'Hard Copy ',value:'hard-copy'}
      ],
      exam_options:[],
      candidate_exam_types: [],
      ask_for_instruments: [
        { text: 'Not Applicable',value:'na'},
        { text: 'Yes',value:'yes'},
        { text: 'No',value:'no'},
      ],
      has_hand: [
        { text: 'Not Applicable',value:'na'},
        { text: 'Yes',value:'yes'},
        { text: 'No',value:'no'},
      ],
      has_instrument_types: [
        { text: 'Not Applicable',value:''},
        { text: 'Yes',value:1},
        { text: 'No',value:2},
      ],
      reports: [
        { text: 'Not Applicable',value:'na'},
        { text: 'Individual',value:'individual'},
        { text: 'Group',value:'group'},
      ],
      allow_group_entry: [
        { text: 'No',value:'no'},
        { text: 'Yes',value:'yes'}
      ],
      require_playlist: [
        { text: 'No',value:0},
        { text: 'Yes',value:1}
      ],
      hide_second_page: [
        { text: 'No',value:'no'},
        { text: 'Yes',value:'yes'}
      ],
      groups_certificates:[
        { text: 'None',value:'na'},
        { text: 'Group Certificate',value:'group_certificate'},
        { text: 'With all name, group theme/piece name',value:'all_detail_certificate'},
        { text: 'Certificate for Individual',value:'individual_certificate'}
      ],
      exam:{
        title:null,
        subject_code:null,
        image:null,
        description: null,
        expiry_months: null,
        exam_duration: null,
        marking_type: [],
        certificate_type:[],
        default_price: null,
        appeal_price: null,
        rising_software_key: null,
        practice_exam_link: null,
        repertoire_submission: null,
        states:[],
        course_id:'',
        renewable_fee_type:'',
        exam_option: '',
        candidate_exam_type:'',
        ask_for_instrument: '',
        has_hand: '',
        has_instrument_type: '',
        report_available: '',
        taken_as_group: '',
        require_playlist: '',
        transfer_fee:null,
        withdrawal_fee: null,
        position:null,
        state_price: true,
        status: true,
        visibility: true,
        hide_second_page: null,
        category_id:'',
        num_of_min_candidate:'',
        num_of_max_candidate:'',
        price_per_additional_candidate:'',
        group_certificate:'',
        sub_category_id:'',
        grade_id:'',
        renewable_fee:'',
        on_special:true,
        special_pricing_text:'',
        notification_pdf_second_page:''
      },
      label:{
        statePriceLabel:'',
        statusLabel:'',
        visibilityLabel:'',
        specialLabel:'',
      },
      categories:[],
      errors:[],
      sub_categories:[],

    }
  },
  methods:{
    getAllGrades(){
      grade
          .getBySubCategory(this.exam.sub_category_id)
          .then(response => {
            this.grades=response.data;
          })
          .catch((err) => {

          });
    },
    getCourses(){
      course
          .getByGrade(this.exam.grade_id)
          .then(response => {
            this.courses=response.data;
          })
          .catch((err) => {

          });
    },
    getCategories(){
      category
          .get()
          .then(response => {
            this.categories=response.data;
          })
          .catch((err) => {

          })
    },
    getSubCategory(){
        subCategory
        .getByCategory(this.exam.category_id)
          .then(response => {
            this.sub_categories=response.data;
          })
            .catch((err) => {

            })
    },
    renewableFeeToggle(){
      if(this.exam.renewable_fee_type=='custom'){
        this.renewable_fee_show=true;
      }else{
        this.renewable_fee_show=false;
      }
    },
    groupEntryToggle(){
      if(this.exam.taken_as_group=='yes'){
        this.group_entry_show=true;
      }else{
        this.group_entry_show=false;
      }
    },
    getStates(){
      state
          .paginate()
          .then((response) => {
            this.states = response.data;
          })
          .catch((error) => {

          })
    },
    convertToFormData() {

      if(this.exam.states!=null){
        this.exam.states = this.exam.states.map(obj => obj.id)
      }
      let formData = new FormData();
      for (let key in this.exam) {
        if (key === "image" && this.exam[key] != null && this.exam[key] != undefined) {
          formData.append('image', this.exam[key]);
        }else {
          if(this.exam[key]){
            formData.append(key,this.exam[key]);
          }
        }
      }
      return formData;
    },
    createOnlineExamination() {

      if(this.examinationId!=null || this.examinationId!=undefined){
        this.$v.$touch()
        if (this.$v.$error) {
          setTimeout(() => {
            this.$v.$reset()
          }, 3000);
        } else {
          let formData = this.convertToFormData();
          examination
              .update(this.examinationId, formData)
              .then(response => {
                this.$snotify.success("Examination updated");

                this.$router.push({
                  name: "online-examination",
                });
              })
              .catch(error => {
                this.errors = error.response.data.errors;
              })
        }
      }else{
        this.$v.$touch()
        if (this.$v.$error) {
          var element = this.$v;
          var top = element.offsetTop;
          window.scrollTo(0, top);
          setTimeout(() => {
            this.$v.$reset()
          }, 6000);
        } else {
          let formData = this.convertToFormData();
          examination
              .create(formData)
              .then(response => {
                this.$snotify.success("Examination added");

                /*this.$router.push({
                  name: "online-examination",
                });*/

              })
              .catch(error => {
                this.errors = error.response.data.errors;
              })
        }
      }

    },
    examinationDetail(){
      if(this.examinationId!=null || this.examinationId!=undefined){
        examination
            .show(this.examinationId)
            .then(response => {
              this.exam=response.data.examination;
              for(let key in response.data.exam_options){
                this.exam_options.push({value:key, text:response.data.exam_options[key]})
              }
              if(this.exam.states==null){
                this.exam.states=[];
              }
              this.exam.status=this.exam.status=="active" ? true :false;
              this.exam.state_price=this.exam.state_price=="state" ? true : false;
              this.exam.visibility=this.exam.visibility=="visible" ? true : false;
              this.exam.on_special=this.exam.on_special == "1" ? true : false;
              this.getSubCategory();
              this.renewableFeeToggle();
              this.groupEntryToggle();
              this.getAllGrades();
              this.getCourses();
              this.title="Update";
              this.imgurl=this.exam.image_path;

            });
      }
    },
    previewImage(){
      if(this.exam.image != null){
        this.imgurl= URL.createObjectURL(this.exam.image)
      }else{
        this.imgurl = null;
      }
    },
    back(){
      this.$router.push({
        name: "online-examination",
      });
    },
    getExamData(){
    examination
          .getExaminationData()
          .then(response => {
            for(let key in response.data.exam_options){
              this.exam_options.push({value:key, text:response.data.exam_options[key]})
            }
            for(let key in response.data.candidateExamTypes){
              this.candidate_exam_types.push({value:key,text:response.data.candidateExamTypes[key] })
            }
          });
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    resetForm(){
     this.exam= {
       title: null,
       subject_code: null,
       image: null,
       description: null,
       expiry_months: null,
       exam_duration: null,
       marking_type: null,
       certificate_type: null,
       default_price: null,
       appeal_price: null,
       rising_software_key: null,
       practice_exam_link: null,
       repertoire_submission: null,
       states: [],
       course_id: '',
       renewable_fee_type: '',
       exam_option: '',
       candidate_exam_type: '',
       ask_for_instrument: '',
       has_hand: '',
       has_instrument_type: '',
       report_available: '',
       taken_as_group: '',
       require_playlist: '',
       transfer_fee: null,
       withdrawal_fee: null,
       position: null,
       state_price: true,
       status: true,
       visibility: true,
       hide_second_page: null,
       category_id: '',
       num_of_min_candidate: '',
       num_of_max_candidate: '',
       price_per_additional_candidate: '',
       group_certificate: '',
       sub_category_id: '',
       grade_id: '',
       renewable_fee: '',
       on_special: true,
       special_pricing_text: '',
       notification_pdf_second_page: ''
     }
    }
  },
  mounted() {
    this.getCategories();
    this.getStates();
    this.examinationDetail();
    this.getExamData();
  },
  computed:{
    examinationId () {
      return this.$route.params.examinationId;
    },
    statePricesLabel: function () {
      if(this.exam && this.exam.state_price==true){
        return this.label.statePriceLabel="State"
      }else{
        return this.label.statePriceLabel="Default"
      }
    },
    statusLabel: function () {
      if(this.exam && this.exam.status==true){
        return this.label.statusLabel="Active"
      }else{
        return this.label.statusLabel="Inactive"
      }
    },
    visibilityLabel: function () {
      if(this.exam && this.exam.visibility==true){
        return this.label.visibilityLabel="Visible"
      }else{
        return this.label.visibilityLabel="Not Visible"
      }
    },
    specialLabel: function () {
      if(this.exam && this.exam.on_special==true){
        return this.label.specialLabel="On"
      }else{
        return this.label.specialLabel="Off"
      }
    },
    routeTab() {
      if(this.examinationId!=null || this.examinationId!=undefined) {
        return {
          title: `Update-${this.exam.title}`,
          tips: `Edit for ${this.exam.title}`
        }
      }
    }
  },

}
</script>