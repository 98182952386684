import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class OnlineExaminationService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/examinations';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;

        }
        if (queryString)
            url = url + '&page=' + index;
        else
            url = url + '?page=' + index;
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    update(id,data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url,data)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    managePrice(id)
    {
        let url = `${this.#api}/${id}/exam-price`
        return apiService.get(url);
    }
    savePrice(id,data)
    {
        let url = `${this.#api}/${id}/manage-price`;
        return apiService.post(url,data);
    }
    getExaminationData() {
        let url = `${this.#api}/create`;
        return apiService.get(url)
    }
}